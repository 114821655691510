const data = [
    {
        id: 1,
        name: 'Power Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools',
        children: [
            { id:11, name: 'Drills', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Drills' },
            { id:12, name: 'Saws', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Saws' },
            { id:13, name: 'Woodworking', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Woodworking' }
    
        ],
    },
    {
        id: 2,
        name: 'Hand Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools',
        children: [
            { id:21, name: 'Wrenches and Pliers', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Wrenches and Pliers' },
            { id:22, name: 'Kits', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Kits' }
],
    },
    {
        id: 3,
        name: 'Machine Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools',
        children: [
   
            { id:31, name: 'Chain saws', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Chain saws' },
            { id:32, name: 'Large tools', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Large tools' }
         ],
    },
    {
        id: 4,
        name: 'Other',
        url: '/shop/category-grid-3-columns-sidebar?cat=Other',
        children: [
            { id: 41, name: 'Compressors', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Compressors' },
            { id: 42, name: 'Plumbing', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Plumbing' }
        ],
    },
    {
        id: 5,
        name: 'Makeup',
        url: '/shop/category-grid-3-columns-sidebar?cat=Makeup',
        children: [
            { id: 51, name: 'Accessories', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Makeup accessories' },
            { id: 52, name: 'Products', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Products' }
        ],
    }
];

export default data;