// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';

// blocks
import BlockFeatures from '../blocks/BlockFeatures';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import theme from '../../data/theme';


function HomePageOne() {
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home Page One — ${theme.name}`}</title>
            </Helmet>
        
            <BlockSlideShow/>

            <div className="block" id="frosmo-home-reco-1"></div>
            <div className="block" id="frosmo-home-reco-2"></div>
            <div className="block" id="frosmo-home-reco-3"></div>

            <BlockFeatures />
        </React.Fragment>
    );
}

export default HomePageOne;
