// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';

// data stubs
import categories from '../../data/shopWidgetCategories';
import products from '../../data/shopProducts';
import theme from '../../data/theme';


function ShopPageProduct(props) {
    const { layout, sidebarPosition, match } = props;
    let product;

    if (match.params.productId) {
        product = products.find((x) => x.id === parseFloat(match.params.productId));
    } else {
        product = products[products.length - 1];
    }

    let category = product.category + '/' + product.subcategory;
    let dlProduct = {
        'name': product.name,
        'category': category,
        'price': product.price,
        'id': product.id,
        'brand':product.brand,
        'url': window.location.origin + '/shop/product/' + product.id,
        'image': window.location.origin + product.images[0]
    };
    if (!window.dataLayer) window.dataLayer = [];
    window.dataLayer.push({

        'ecommerce': {
            'detail': {
                'actionField': {'list': category},
                'products': [dlProduct]
            }

        }
    });

    window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': {'list': category},
                'products': [dlProduct]
            }

        }
    });

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: product.category, url: '' },
        { title: product.subcategory, url: '' },
    ];

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">

                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs withSidebar />
                        </div>


                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} />
                        <ProductTabs />
                    </div>
                </div>


            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPageProduct;
