// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


function AppLink(props) {
    const { external, children, ...otherProps } = props;
    const link = <Link {...otherProps}>{children}</Link>;

    return link;
}

AppLink.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            state: PropTypes.any,
        }),
    ]).isRequired,
    replace: PropTypes.bool,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default AppLink;
