const data = {
    name: 'Frosmo',
    fullName: 'Frosmo - Example Store React',
    url: 'https://www.frosmo.com',
    author: {
        name: 'Kos',
        profile_url: 'https://www.frosmo.com',
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'contact@example.com',
        phone: '(800) 060-0730',
    },
};

export default data;