const shopCurrencies = [
    {
        title: '€ Euro',
        currency: {
            code: 'EUR',
            symbol: '€',
            name: 'Euro',
        },
    },
    {
        title: '£ Pound Sterling',
        currency: {
            code: 'GBP',
            symbol: '£',
            name: 'Pound Sterling',
        },
    },
    {
        title: '$ US Dollar',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'US Dollar',
        },
    },
];

export default shopCurrencies;
