const addresses = [
    
    {
        id: 1,
        default: false,
        firstName: 'Dennis',
        lastName: 'Demoer',
        email: 'dd@example.com',
        phone: 'ZX 971 972-57-26',
        country: 'RandomLand',
        city: 'MarsGrad',
        postcode: '4b4f53',
        address: 'Sun Orbit, 43.3241-85.239',
    },
    {
        id: 2,
        default: true,
        firstName: 'Helena',
        lastName: 'Garcia',
        email: 'hg@example.com',
        phone: '38 972 588-42-36',
        country: 'Random Republic',
        city: 'Springfield',
        postcode: '115302',
        address: '2nd Street, 152178',
    },
];
export default addresses;