const data = [
    {
        title: 'Power Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools',
        products: 572,
        image: 'images/products/product-1.jpg',
        subcategories: [
            { title: 'Drills', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Drills' },
            { title: 'Saws', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Saws' },
            { title: 'Woodworking', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Woodworking' }
       ],
    },
    {
        title: 'Hand Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools',
        products: 134,
        image: 'images/products/product-16.jpg',
        subcategories: [
            { title: 'Wrenches and Pliers', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Wrenches and Pliers' },
                { title: 'Kits', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Kits' }
     
        ],
    },
    {
        title: 'Machine Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools',
        products: 301,
        image: 'images/products/product-6.jpg',
        subcategories: [
            { title: 'Chain saws', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Chain saws' },
                { title: 'Large tools', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Large tools' }
           
        ],
    },
    {
        title: 'Other',
        url: '/shop/category-grid-3-columns-sidebar?cat=Other',
        products: 79,
        image: 'images/products/product-14.jpg',
        subcategories: [
            { title: 'Compressors', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Compressors' },
                { title: 'Plumbing', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Plumbing' }
            
        ],
    },
    {
        title: 'Makeup',
        url: '/shop/category-grid-3-columns-sidebar?cat=Makeup',
        products: 79,
        image: 'images/products/huulipuna1.jpg',
        subcategories: [
            { title: 'Products', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Makeup Products' },
            { title: 'Accessories', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Makeup accessories' }
            
        ],
    },
    
];

export default data;