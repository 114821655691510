// react
import React from 'react';

// third-party
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

// application
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';

// styles
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './scss/style.scss';

window.dataLayer = window.dataLayer || [];

const root = createRoot(document.getElementById('root'));
root.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
        <Root />
    </Provider>
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
