const data = [
    {
        type: 'link',
        label: 'Home',
        url: '/',
        
    },
    {
        type: 'link',
        label: 'Categories',
        url: '',
        children: [
            {
                type: 'link',
                label: 'Power Tools',
                url: '/shop/category-list?cat=Power Tools',
                children: [
                    { type: 'link', label: 'Drills', url: '/shop/category-list?cat=Power Tools&subcat=Drills' },
                    { type: 'link', label: 'Saws', url: '/shop/category-list?cat=Power Tools&subcat=Saws' },
                    { type: 'link', label: 'Woodworking', url: '/shop/category-list?cat=Power Tools&subcat=Woodworking' },
                ],
            },
            {
                type: 'link',
                label: 'Hand Tools',
                url: '/shop/category-list?cat=Hand Tools',
                children: [
                    { type: 'link', label: 'Wrenches and Pliers', url: '/shop/category-list?cat=Hand Tools&subcat=Wrenches and Pliers' },
                    { type: 'link', label: 'Kits', url: '/shop/category-list?cat=Hand Tools&subcat=Kits' },
                ],
            },
            {
                type: 'link',
                label: 'Machine Tools',
                url: '/shop/category-list?cat=Machine Tools',
                children: [
                    { type: 'link', label: 'Chain saws', url: '/shop/category-list?cat=Machine Tools&subcat=Chain saws' },
                    { type: 'link', label: 'Large tools', url: '/shop/category-list?cat=Machine Tools&subcat=Large tools' },
                ],
            },
            {
                type: 'link',
                label: 'Other',
                url: '/shop/category-list?cat=Other',
                children: [
                    { type: 'link', label: 'Compressors', url: '/shop/category-list?cat=Other&subcat=Compressors' },
                    { type: 'link', label: 'Plumbing', url: '/shop/category-list?cat=Other&subcat=Plumbing' },
                ],
            },
        ],
    },
    {
        type: 'link',
        label: 'Account',
        url: '/account',
        children: [
            { type: 'link', label: 'Login', url: '/account/login' },
            { type: 'link', label: 'Dashboard', url: '/account/dashboard' },
            { type: 'link', label: 'Edit Profile', url: '/account/profile' },
            { type: 'link', label: 'Order History', url: '/account/orders' },
            { type: 'link', label: 'Address Book', url: '/account/addresses' },
            { type: 'link', label: 'Change Password', url: '/account/password' },
        ],
    },

    {
        type: 'link',
        label: 'Blog',
        url: '/blog/category-classic',
        children: [
            { type: 'link', label: 'Blog Classic', url: '/blog/category-classic' },
            { type: 'link', label: 'Blog Grid', url: '/blog/category-grid' },
            { type: 'link', label: 'Blog List', url: '/blog/category-list' },
            { type: 'link', label: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
            { type: 'link', label: 'Post Page', url: '/blog/post-classic' },
            { type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full' },
        ],
    },

    {
        type: 'link',
        label: 'Pages',
        url: '/site/about-us',
        children: [
            { type: 'link', label: 'About Us', url: '/site/about-us' },
            { type: 'link', label: 'Contact Us', url: '/site/contact-us' },
            { type: 'link', label: 'Contact Us Alt', url: '/site/contact-us-alt' },
            { type: 'link', label: '404', url: '/site/not-found' },
            { type: 'link', label: 'Terms And Conditions', url: '/site/terms' },
            { type: 'link', label: 'FAQ', url: '/site/faq' },
            { type: 'link', label: 'Components', url: '/site/components' },
            { type: 'link', label: 'Typography', url: '/site/typography' },
        ],
    },

    {
        type: 'button',
        label: 'Currency',
        children: [
            { type: 'button', label: '€ Euro', data: { type: 'currency', code: 'EUR' } },
            { type: 'button', label: '£ Pound Sterling', data: { type: 'currency', code: 'GBP' } },
            { type: 'button', label: '$ US Dollar', data: { type: 'currency', code: 'USD' } },
            
        ],
    },

    {
        type: 'button',
        label: 'Language',
        children: [
            { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
           
        ],
    },
];

export default data;